<template>
  <div class="finance-bill-detail-page">
    <el-card shadow="hover" style="margin-bottom: 16px">
      <div class="row no-gutters">
        <div class="col">
          <div class="box-title">
            <span style="font-size: 16px">收入</span>
            <span> ({{ gailan.income_count || 0 }}笔)</span>
          </div>
          <div class="box-value" style="color: #e3405b;">
            <span v-if="gailan.income!=0" style="position:absolute; margin-top:8px; font-size: 20px">+</span>
            <span style="margin-left:16px;font-size: 32px">{{ (gailan.income || 0) | unitPrice('') }}</span>
          </div>
        </div>
        <div class="col">
          <div class="box-title">
            <span style="font-size: 16px">支出</span>
            <span> ({{ gailan.outcome_count || 0 }}笔)</span>
          </div>
          <div class="box-value" style="color: #5dbe2d;  font-size: 32px">
            <span style="margin-left:16px;font-size: 32px">{{ (gailan.outcome || 0) | unitPrice('') }}</span>
          </div>
        </div>
      </div>
    </el-card>

    <en-table-layout
      :tableData="pageData.data"
      :redundancy-height="130"
      @selection-change="handleSelectionChange"
      v-if="!is_JdSupplier"
    >
      <template slot="toolbar">
        <el-form-item label="选择日期" class="col-auto">
          <el-date-picker
            style="width: 220px;"
            v-model="daterangeDate"
            type="daterange"
            size="medium"
            @change="daterangeDateChange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          />
        </el-form-item>

        <el-form-item label="交易类型" class="col-auto">
          <el-select
            size="medium"
            style="width: 150px;"
            v-model="params.flow_type"
            placeholder="选择交易类型"
            clearable
          >
            <el-option
              v-for="item in list1"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="关键词" class="col-auto">
          <el-input
            placeholder="请输入关键字"
            size="medium"
            v-model="params.sn_query"
          >
          >
            <template slot="prepend">
              <el-select
                size="medium"
                style="width: 90px"
                v-model="params.sn_type"
              >
                <el-option :value="1" label="流水号"></el-option>
                <el-option :value="2" label="订单号" v-if="shopInfo.shop_type !== 2"></el-option>
                <el-option :value="5" label="订单号" v-else></el-option>
              </el-select>
            </template>
          </el-input>
        </el-form-item>

        <div class="col-auto">
          <el-button
            type="primary"
            size="small"
            :loading="loading"
            @click="getList()"
          >
            搜索
          </el-button>
        </div>

        <div class="col"></div>

        <div class="col-auto">
          <el-button
            type="primary"
            size="small"
            :loading="importLoading"
            @click="downZd()"
          >
            下载账单
          </el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="流水号" width="200" fixed="left">
          <template slot-scope="scope">{{ scope.row.sn }}</template>
        </el-table-column>

        <el-table-column label="日期" width="100" fixed="left">
          <template slot-scope="scope">
            {{ scope.row.order_create_time | unixToDate('yyyy-MM-dd') }}
          </template>
        </el-table-column>

        <el-table-column label="内容" width="400" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.order_content }}
          </template>
        </el-table-column>

        <el-table-column label="会员名称" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="flow_type_value(scope.row, null)==='提现'">{{scope.row.user_name}}</span>
            <span v-else>{{scope.row.member_name}}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="交易类型"
          props="flow_type"
          width="120"
          :formatter="flow_type_value"
        ></el-table-column>

        <el-table-column label="交易金额" width="120">
          <!-- 判断有无账期供应商 来判断显示不同的字段 -->
          <template slot-scope="scope">
            <span v-if="shopInfo.account_period === 1">
              <span v-if="scope.row.revenue_expense_cash>0">+</span>
            </span>
            <span v-else>
              <span v-if="scope.row.revenue_expense_amount>0">+</span>
            </span>{{
              shopInfo.account_period === 1
                ? scope.row.revenue_expense_cash
                : scope.row.revenue_expense_amount
            }}
          </template>
        </el-table-column>
        <el-table-column label="余额" width="120">
          <template slot-scope="scope">{{
              shopInfo.account_period === 1
                ? scope.row.cash_account
                : scope.row.balance_account | unitPrice('￥')
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleStockGoods(scope.row)"
              :disabled="flow_type_value(scope.row, null)==='退款'"
            >详情
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no"
        :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="pageData.data_total"
      ></el-pagination>
    </en-table-layout>

    <en-table-layout
      :tableData="pageData.data"
      :redundancy-height="130"
      @selection-change="handleSelectionChange"
      v-if="is_JdSupplier"
    >
      <template slot="toolbar">
        <el-form-item label="选择日期" class="col-auto">
          <el-date-picker
            style="width: 220px;"
            v-model="daterangeDate"
            type="daterange"
            size="medium"
            @change="daterangeDateChange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          />
        </el-form-item>

        <el-form-item label="交易类型" class="col-auto">
          <el-select
            size="medium"
            style="width: 150px;"
            v-model="params.flow_type"
            placeholder="选择交易类型"
            clearable
          >
            <el-option
              v-for="item in list1"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="关键词" class="col-auto">
          <el-input
            placeholder="请输入关键字"
            size="medium"
            v-model.trim="params.sn_query"
            clearable
          >
            >
            <template slot="prepend">
              <el-select
                size="medium"
                style="width: 110px"
                v-model="params.sn_type"
              >
                <el-option
                  v-for="item in list2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </template>
          </el-input>
        </el-form-item>

        <div class="col-auto">
          <el-button
            type="primary"
            size="small"
            :loading="loading"
            @click="getList()"
          >
            搜索
          </el-button>
        </div>

        <div class="col"></div>

        <div class="col-auto">
          <el-button
            type="primary"
            size="small"
            :loading="importLoading"
            @click="downZd()"
          >
            下载账单
          </el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="流水号" width="150" fixed="left">
          <template slot-scope="scope">{{ scope.row.sn }}</template>
        </el-table-column>

        <el-table-column label="京东单号" width="150" fixed="left">
          <template slot-scope="scope">{{ scope.row.ext_order_id }}</template>
        </el-table-column>

        <el-table-column label="日期" width="100">
          <template slot-scope="scope">
            {{ scope.row.order_create_time | unixToDate('yyyy-MM-dd') }}
          </template>
        </el-table-column>

        <el-table-column label="内容" width="400" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.order_content }}
          </template>
        </el-table-column>

        <el-table-column label="所属企业" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.enterprise_name }}
          </template>
        </el-table-column>

        <el-table-column
          label="交易类型"
          props="flow_type"
          :formatter="flow_type_value"
        ></el-table-column>

        <el-table-column label="交易金额" width="120">
          <!-- 判断有无账期供应商 来判断显示不同的字段 -->
          <template slot-scope="scope">
            <span v-if="shopInfo.account_period === 1">
              <span v-if="scope.row.revenue_expense_cash>0">+</span>
            </span>
            <span v-else>
              <span v-if="scope.row.revenue_expense_amount>0">+</span>
            </span>{{
              shopInfo.account_period === 1
                ? scope.row.revenue_expense_cash
                : scope.row.revenue_expense_amount
            }}
          </template>
        </el-table-column>
        <el-table-column label="余额" width="120">
          <template slot-scope="scope">{{
              shopInfo.account_period === 1
                ? scope.row.cash_account
                : scope.row.balance_account | unitPrice('￥')
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleStockGoods(scope.row)"
              :disabled="flow_type_value(scope.row, null)!=='采购'"
            >详情
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no"
        :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="pageData.data_total"
      ></el-pagination>
    </en-table-layout>
    <!-- 查看 -->
    <x-dialog :proxy="showWithDrawalInfoProxy">
      <el-form :model="goodsAuditForm" label-width="120px">
        <el-form-item label="提现金额" prop="cash_money">
          <span>{{ goodsAuditForm.cash_money | unitPrice("￥") }}(手续费会在提现金额中扣除)</span>
        </el-form-item>
        <el-form-item label="提现手续费" prop="fee_money">
          <span>{{ goodsAuditForm.fee_money | unitPrice("￥") }}</span>
        </el-form-item>
        <el-form-item label="转账截图">
          <div style="display: flex;">
            <img
              style="width:100px;height:100px;margin: 0 5px 20px;"
              v-for="(item, index) in goodsAuditForm.auth_imgs_list"
              @click="handleImgLook(item)"
              :src="item"
              :key="index"
            />
          </div>
        </el-form-item>
        <el-form-item label="备注" prop="comment">
          <span>{{ goodsAuditForm.comment || "无" }}</span>
        </el-form-item>
      </el-form>
    </x-dialog>

    <!-- 查看大图 -->
    <el-dialog :visible.sync="centerDialogVisible" modal close-on-click-modal custom-class="dialog">
      <el-carousel :autoplay="false" arrow="never">
        <el-carousel-item v-for="item in centerDialogList" :key="item">
          <img :src="item" style="width: 100%; height: 100%" alt="" />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
import * as API_account from '@/api/account';
// import * as API_logistics from '@/api/expressCompany';
import {handleDownload} from '@/utils';
import {Foundation} from '@/../ui-utils';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
import xDialog from '@/components/x-dialog/x-dialog'
import { $xDialog } from "@/components/x-dialog/dialog.proxy";

export default {
  name: 'goodsList',
  components: {EnTableLayout, xDialog},
  data() {
    return {
      centerDialogVisible: false, // 显示查看器
      centerDialogList: [],
      showWithDrawalInfoProxy: $xDialog.create({
        title: '提现详情',
        width: '50vw',
        disableCancel: true,
        disableConfirm: true
      }),
      goodsAuditForm: {},
      activeName: 'first',
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      idsList: [],

      list1: [
      ],
      list2: [
        {
          value: 1,
          label: '流水号'
        },
        {
          value: 5,
          label: '订单号'
        },
        {
          value: 7,
          label: '所属企业'
        }
      ],

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        shop_name: '',
        seller_name: '',
        start_time: '',
        end_time: '',
        logi_id: '',
        pay_type: '',
        ca_type: 0,
        sn_type: 1,
      },
      gailan: {},
      daterangeDate: '',

      /** 列表数据 */
      tableData: {},

      /** 列表分页数据 */
      pageData: {
        data: [],
      },
      pageData1: {
        data: [],
      },

      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近7日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近15日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近30日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      is_JdSupplier: false
    };
  },
  activated() {
    this.GET_AccountList();
    this.GET_getGaiLan();
  },
  mounted() {
    this.is_JdSupplier = this.$store.getters.shopInfo.shop_id === 66
    this.GET_getFlowTypeList();
    this.GET_AccountList();
    this.GET_getGaiLan();
    if (this.is_JdSupplier) {
      this.list2.push({
        value: 6,
        label: '京东单号'
      })
    }
  },
  watch: {
    daterangeDate: {
      handler: 'daterangeDateChange',
      deep: true
    }
  },
  methods: {
    handleImgLook(url) {
      console.log(url);
      this.centerDialogList = [url];
      // this.showViewer = true;
      this.centerDialogVisible = true;
    },
    daterangeDateChange(val) {
      this.params.start_time = val ? parseInt(val[0] / 1000) : '';
      this.params.end_time = val ? parseInt(val[1] / 1000) : '';
    },
    flow_type_value(row, column) {
      let obj = this.list1.find((value, index, arr) => {
        return row.flow_type == value.code;
      });
      if (obj) {
        return obj.name;
      }
    },
    getList() {
      this.GET_getGaiLan();
      this.GET_AccountList();
    },
    downZd(row) {
      let params = { ...this.downZd}
      if (!row) {
        this.importLoading = true;
      }
      if (this.shopInfo.shop_type === 2) {
        params.settle_status = 0;
      } else {
        params.shop_settle_status = 0;
      }
      delete params.page_no;
      delete params.page_size;
      API_account.getAccountFlowList(params).then(response => {
        response.forEach(item => {
          item.order_create_time = Foundation.unixToDate(
            item.order_create_time,
            'yyyy-MM-dd '
          );
          const result = this.list1.find((value, index, arr) => {
            return value.code == item.flow_type;
          });
          if (result) {
            item.flow_type = result.name;
          } else {
            item.flow_type = '！异常:' + item.flow_type;
          }
        });
        let tHeaders = [
          '流水号',
          '日期',
          '内容',
          '所属企业',
          '交易类型',
          '交易金额',
          '余额'
        ];
        let filterVals = [
          'sn',
          'order_create_time',
          'order_content',
          'enterprise_name',
          'flow_type',
          this.shopInfo.account_period === 1 ? 'revenue_expense_cash' : 'revenue_expense_amount',
          this.shopInfo.account_period === 1 ? 'cash_account' : 'balance_account',
        ];
        handleDownload(response, tHeaders, filterVals, '财务明细');
        this.importLoading = false;
      });
    },

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 分页大小发生改变 */
    handlePageSizeChange1(size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange1(page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 搜索事件触发 */
    searchEvent(data) {
      this.params = {
        ...this.params,
        keyword: data
      };
      this.GET_AccountList();
    },
    /**获取流水类型信息 */
    GET_getFlowTypeList() {
      API_account.getFlowTypeList({use_type: 4}).then(response => {
        if (this.is_JdSupplier) {
          let code_list = ['2', '7', '10']
          this.list1 = response.filter(item => {
            return code_list.includes(item.code)
          })
        } else {
          this.list1 = response;
        }
      });
    },
    GET_getGaiLan() {
      let pa = {};
      pa.start_time = this.params.start_time;
      pa.end_time = this.params.end_time;
      pa.flow_type = this.params.flow_type;
      pa.sn_type = this.params.sn_type;
      pa.sn_query = this.params.sn_query;
      API_account.getGaiLan(pa).then(res => {
        this.gailan = res;
      });
    },
    GET_AccountList() {
      this.loading = true;

      if (this.shopInfo.shop_type === 2) {
        this.params.settle_status = 0;
      } else {
        this.params.shop_settle_status = 0;
      }

      API_account.getAccountFlowPage(this.params).then(response => {
        this.loading = false;
        this.pageData = response;
      });
    },

    handleSelectionChange(val) {
      let ids = [];
      val.forEach(item => {
        ids.push(item.order_id);
      });
      this.idsList = ids;
    },
    settlement() {
      if (this.idsList.length) {
        API_account.checkaccountAdd({
          orderId: this.idsList,
          caDirection: this.params.ca_type
        }).then(res => {
          this.$message.success('发起结算成功');
          this.GET_AccountList();
        });
      } else {
        this.$message.error('请勾选数据');
      }
    },
    handleStockGoods(row) {
      console.log(row)
      if(this.flow_type_value(row, null) === '提现') {
        API_account.getBalanceDetail(row.order_id).then((res) => {
          this.goodsAuditForm = res;
          this.showWithDrawalInfoProxy.display();
        });
      } else {
        let route = this.$route.path.split('/')[2];
        this.$router.push({path: `${route}/orderDetail/${row.order_sn}`});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
